import CoreTools from "./CoreTools";

const notify = (message, status, time) => window.cammCtrl.notify(message, status, time);

const process = (code, contentType, text, cb, path) => {
    if (code !== 200) return notify(`Invalid API response code '${code}' from '${path}'`, "danger", 10);
    if (typeof(contentType) !== "string") return notify(`API '${path}' failed to respond with Content-Type.`, "danger", 10);
    if (!contentType.split(";").map(ct => ct.trim()).includes("application/json")) return notify(`Invalid API Content-Type '${contentType}' from '${path}'.`, "danger", 10);
    let data = {};
    try {
        data = JSON.parse(text);
    } catch (ex) {
        console.error("API parse error:", ex.message, "\r\n", ex.stack);
        return notify(`Error parsing JSON text, '${ex.message}', '${path}'.`);
    }
    if (!CoreTools.isObject(data)) return notify(`API response was not in the form of an Object, '${path}'.`, "danger", 10);
    if (data.message) Array.isArray(data.message) ? notify(...data.message) : notify(data.message, "info", 10);
    if (data.console) Array.isArray(data.console) ? console.log(...data.console) : console.log(data.console);
    if (data.navigate) Array.isArray(data.navigate) ? setInterval(() => window.location.href = data.navigate[0], data.navigate[1]) : setTimeout(() => window.location.href = data.navigate);
    if (data.location) Array.isArray(data.location) ? setTimeout(() => window.location.href = data.location[0], data.location[1] || 0) : setTimeout(() => window.location.href = data.location);
    if (typeof(cb) === "function") cb(data);
};

const ajax = (method, url, headers, query, data, cb) => {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => xhttp.readyState === 4 && cb(xhttp.status, xhttp.getResponseHeader("Content-Type"), xhttp.responseText);
    xhttp.open(method, CoreTools.isObject(query) ? [url, Object.keys(query).reduce((p, key) => {
        p.push([encodeURIComponent(key), encodeURIComponent(query[key])].join("="));
        return p;
    }, []).join("&")].join("?") : url, true);
    Object.keys(headers || {}).forEach(key => xhttp.setRequestHeader(key, headers[key]));
    xhttp.send(["POST", "PUT"].includes(method) ? data : undefined);
};

const Api = (endpoint, headers, query, data, cb) => CoreTools.isObject(data) ? 
ajax(
    "POST",
    endpoint,
    Object.assign({"Content-Type": "application/json", "Accept": "application/json"}, headers),
    query,
    JSON.stringify(data),
    (code, contentType, text) => process(
        code,
        contentType,
        text,
        cb,
        endpoint
    )
) :
ajax(
    "GET",
    endpoint,
    Object.assign({"Accept": "application/json"}, headers),
    query,
    undefined,
    (code, contentType, text) => process(
        code,
        contentType,
        text,
        cb,
        endpoint
    )
);

export default Api;