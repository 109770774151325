import React from "react";

const Input = props => {
    return <div className="row justify-content-center mt-3">
        <div className="col-12">
            <label htmlFor={props.name} className="form-label">{props.label}</label>
            <div className="input-group">
                {props.icon && <span className="input-group-text bg-secondary text-white">
                    <i className={props.icon}></i>
                </span>}
                <input type={props.type} name={props.name} className="form-control input-md" id={props.name} defaultValue={props.defaultValue} />
            </div>
        </div>
    </div>;
};

const Password = props => {
    const [showPassword, setShowPassword] = React.useState(false);
    React.useEffect(() => window.cammCtrl.tooltipUpdate(), [showPassword]);
    return <div className="row justify-content-center mt-3">
        <div className="col-12">
            <label htmlFor={props.name} className="form-label">{props.label}</label>
            <div className="input-group">
                {props.icon && <span className="input-group-text bg-secondary text-white">
                    <i className={props.icon}></i>
                </span>}
                <input type={showPassword ? "text" : "password"} name={props.name} className="form-control input-md" id={props.name} />
                <span className="input-group-text bg-secondary text-white tt" style={{cursor: "pointer"}} onClick={() => setShowPassword(!showPassword)} data-bs-placement="top" title={showPassword ? "Click To Hide" : "Click To Show"}>
                    {   
                        showPassword ?
                        <i className="bi bi-eye-slash-fill"></i> :
                        <i className="bi bi-eye-fill"></i>
                    }
                </span>
            </div>
        </div>
    </div>;
};

const FormElem = {
    Input: Input,
    Password: Password
};

export default FormElem;