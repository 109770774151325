const CardContainer = props => <div style={{
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
}}>
    <div className="container-sm rounded-3 my-5 p-3 col-lg-6" style={{
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        boxShadow: "3px 5px 3px rgba(0, 0, 0, 0.75)",
        maxWidth: "500px"
    }}>{props.children}</div>
</div>;
export default CardContainer;