import CoreTools from "../../../shared/CoreTools";
import FormElem from "./FormElem";
import Api from "../../../shared/Api";
import Notify from "../../../shared/Notify";

const PanelLogin = () => {

    const submit = values => Api(
        "/api/sign-in.api",
        null,
        values,
        null,
        res => {
            if (res.success) setTimeout(() => window.location.reload(), 3000);
        }
    );

    return <form onSubmit={CoreTools.fromForm(submit)}>
        <h3>Sign In</h3>
        <div className="row">
            <div className="col-12">
                <Notify.LastMessage />
            </div>
        </div>
        <FormElem.Input type="email" name="email" label="Email Address:" icon="bi bi-envelope" defaultValue={""} />

        <FormElem.Password name="password" label="Password:" icon="bi bi-key-fill" />

        <div className="row justify-content-center mt-4">
            <div className="col-6">
                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary">Sign In</button>
                </div>
            </div>
        </div>
    </form>;
};
export default PanelLogin;