const Frame = props => <div style={{width: "100%", height: "100%"}}>
    <div className="container-fluid" style={{backgroundColor: "#FFFFFF", boxShadow: "0px 5px 3px rgba(0, 0, 0, 0.75)"}}>
      <div className="p-1">
        <div className="row">
          <div className="col-6"><img src="https://graphics.cammsoft.com/img/65a4b658f79fb2d126e01b9e" style={{maxHeight: "40px", maxWidth: "100%"}} alt="Camm Logo" /></div>
          <div className="col-6 text-end mt-1" style={{fontSize: "22px"}}>Gate Console</div>
        </div>
      </div>
    </div>
    {props.children}
    <div className="container-fluid" style={{backgroundColor: "#FFFFFF", boxShadow: "0px -5px 3px rgba(0, 0, 0, 0.75)", position: "absolute", bottom: "0px", width: "100%"}}>
        <div className="container-md px-1 py-2">
            <div className="row">
                <div className="col-12 text-center">Copyright © CammSoft/CammServices 2007-2024</div>
            </div>
        </div>
    </div>
</div>;

export default Frame;