import React from 'react'
import ReactDOM from 'react-dom/client'
import CoreTools from '../../shared/CoreTools.js';
import Frame from './content/Frame.jsx';
import PanelLogin from './content/PanelLogin.jsx';
import CardContainer from './content/CardContainer.jsx';
import "../../shared/Notify.jsx";

window.cammCtrl.tooltipUpdate = () => Array.from(document.getElementsByClassName("tt")).forEach(tt => {
    if (tt.getAttribute("data-bs-original-title") !== tt.title) {
        tt.setAttribute("data-bs-original-title", tt.title);
        if (tt.eTip) tt.eTip.dispose();
        if (typeof(bootstrap) !== "undefined") tt.eTip = new bootstrap.Tooltip(tt);
    }
    if (typeof(bootstrap) !== "undefined" && !tt.eTip) tt.eTip = new bootstrap.Tooltip(tt);
});

const dependencies = [
    ["css", "https://assets.cammsoft.com/fontawesome-free-6.2.0-web/css/all.min.css"],
    ["css", "https://assets.cammsoft.com/bootstrap-5.3.2-dist/css/bootstrap.min.css"],
    ["css", "https://assets.cammsoft.com/bootstrap-icons-1.11.1/font/bootstrap-icons.css"],
    // ["css", "/css/admin.css"],
    ["js", "https://assets.cammsoft.com/bootstrap-5.3.2-dist/js/bootstrap.bundle.min.js"]
];

dependencies.forEach(d => {
    const clean = () => {
        CoreTools.remove(dependencies, d);
        if (dependencies.length === 0) {
            window.cammCtrl.tooltipUpdate();
            window.cammCtrl.emit("dependencies_ready");
        }
    };
    if (d[0] === "css") CoreTools.importStyle(d[1], clean);
    else if (d[0] === "js") CoreTools.importScript(d[1], clean)
});

Object.assign(document.body.style, {
    "background": `url('https://graphics.cammsoft.com/img/659e6693a51c34cbc723ba08') no-repeat center center fixed`,
    "background-size": "cover",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    "padding": "0px 0px 100px 0px"
});

const App = () => {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => window.cammCtrl.on("dependencies_ready", () => setReady(true)));

    return ready && <Frame>
        <CardContainer>
            <PanelLogin />
        </CardContainer>
    </Frame>;
};

const appDiv = document.createElement("div");
document.body.appendChild(appDiv);
ReactDOM.createRoot(appDiv).render(<App />);