const events = [];

window.topIndex = 100;

const CoreTools = {
    emit: (name, ...data) => events.filter(watcher => watcher.name === name).map(watcher => watcher.callback(...data)),
    on: (name, callback) => {
        const watcher = {name: name, callback: callback};
        events.push(watcher);
        return () => CoreTools.remove(events, watcher);
    },
    topIndex: () => ++window.topIndex,
    isObject: value => value !== null && typeof(value) === "object" && !Array.isArray(value),
    getCookie: cookieName => {
        let i, x, y, ARRcookies = document.cookie.split(";"), rValue = "";
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substring(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substring(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, ""); 
            if (x === cookieName) {rValue = decodeURIComponent(y);}
        }
        return rValue;
    },
    setCookie: (cookieName, cookieValue, expireInDays) => {
        let expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + (expireInDays || 30));
        document.cookie = [
            `${cookieName}=${encodeURIComponent(cookieValue)}`,
            `expires=${expiryDate.toUTCString()}`,
            window.location.protocol === "https" && "Secure",
            `SameSite=Lax`
        ].filter(e => e).join("; ");
    },
    importStyle: (href, callback) => {
        const existing = Array.from(document.head.childNodes).find(h => h.tagName === "LINK" && (h.getAttribute("href") || "").split("?")[0] === (href || "").split("?")[0]);
        if (existing) {
            if (typeof(callback) === "function") setTimeout(() => callback(existing));
            return existing;
        } else {
            const css = document.createElement("LINK");
            if (typeof(callback) === "function") css.onload = () => setTimeout(() => callback(css));
            css.setAttribute("type", "text/css");
            css.setAttribute("rel", "stylesheet");
            css.setAttribute("href", href);
            document.head.appendChild(css);
            return css;
        }
    },
    importScript: (url, callback, crossorigin, id, parent) => {
        const existing = Array.from(document.head.childNodes).find(h => h.tagName === "SCRIPT" && (h.getAttribute("src") || "").split("?")[0] === (url || "").split("?")[0]);
        if (existing) {
            if (typeof(callback) === "function") setTimeout(() => callback(existing), 250);
            return existing;
        } else {
            const script = document.createElement("script");
            if (typeof(callback) === "function") script.onload = () => setTimeout(() => callback(script), 250);
            script.setAttribute("type", "text/javascript");
            if (crossorigin) script.setAttribute("crossorigin", "*");
            if (id) script.setAttribute("id", id);
            script.setAttribute("src", url);
            (parent || document.head).appendChild(script);
            return script;
        }
    },
    orderBy: (arr, field, descending) => {
        let results = arr.length ? [arr[0]] : [];
        const sortType = value => {
            if (typeof(value) === "string") {return value;}
            else if (typeof(value) === "number") {return value;}
            else if (typeof(value) === "boolean") {return value ? "0" : "1";}
            else {return "";}
        };
        for (let i = 1; i < arr.length; i++) {
            let match = false;
            for (let ii = 0; ii < results.length; ii++) {
                if ((descending && sortType(arr[i][field]) >= sortType(results[ii][field])) || (!descending && sortType(arr[i][field]) <= sortType(results[ii][field]))) {
                    results.splice(ii, 0, arr[i]);
                    match = true;
                    break;
                }
            }
            if (!match) {results.push(arr[i]);}
        }
        return results;
    },
    switch: (...params) => {
        if (typeof(params[0]) === "object" && Array.isArray(params[0])) {
            for (let i = 0; i < params.length; i++) {
                if (typeof(params[i][0]) === "function" ? params[i][0]() : params[i][0]) return typeof(params[i][1]) === "function" ? params[i][1]() : params[i][1];
            }
        } else if (params.length >= 1) {
            if (typeof(params[1]) === "object" && Array.isArray(params[1])) {
                for (let i = 1; i < params.length; i++) {
                    if (typeof(params[i][0]) === "function" ? params[i][0]() === params[0] : params[i][0] === params[0]) return typeof(params[i][1]) === "function" ? params[i][1]() : params[i][1];
                }
            } else if (params.length >= 2) {
                for (let i = 2; i < params.length; i++) {
                    if (typeof(params[i][0]) === "function" ? params[i][0]() === params[0] : params[i][0] === params[0]) return typeof(params[i][1]) === "function" ? params[i][1]() : params[i][1];
                }
                return params[1];
            }
        }
    },
    fromForm: callback => event => {
        if (typeof(event.preventDefault) === "function") event.preventDefault();
        const formSet = {};
        [
            ...Array.from(event.target.getElementsByTagName("input")).map(i => CoreTools.switch(
                [i.type === "checkbox", () => ({name: i.name, value: i.checked ? true : false})],
                [i.type === "radio", () => i.checked ? {name: i.name, value: i.value} : null],
                [i.type === "number" || i.subtype === "number", () => ({name: i.name, value: [null, ""].includes(i.value) ? null : (Number(i.value) || 0)})],
                [true, () => ({name: i.name, value: typeof(i.value) === "string" ? i.value.trim() : i.value})]
            )),
            ...Array.from(event.target.getElementsByTagName("select")).map(i => ({name: i.name, value: i.getAttribute("subtype") === "number" ? (Number(i.value) || 0) : i.value})),
            ...Array.from(event.target.getElementsByTagName("textarea")).map(i => ({name: i.name, value: i.value}))
        ].filter(i => CoreTools.isObject(i) && i.name).forEach(i => formSet[i.name] = i.value);
        if (typeof(callback) === "function") callback(formSet);
        else return formSet;
    },
    setTheme: theme => Object.keys(theme || {}).forEach(key => document.documentElement.style.setProperty(key, theme[key])),
    randomString: len => {
        const characters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let rndString = "";
        for (let i = 0; i < (len || 10); i++) {rndString += characters[Math.floor(Math.random() * characters.length)];}
        return rndString;
    },
    createKey: len => {
        const nk = Date.now().toString(16);
        return nk + CoreTools.randomString((len || 32) - nk.length);
    },
    fuseObj: (...obj) => obj.filter(o => CoreTools.isObject(o)).reduce((p, o) => {
        Object.keys(o).forEach(key => p[key] = o[key]);
        return p;
    }, {}),
    asPhone: source => {
        const s2 = typeof(source) === "string" ? source.replace(/\D/g, "").slice(-10) : "";
        const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? s2 : "(" + m[1] + ") " + m[2] + "-" + m[3];
    },
    remove: (obj, element) => {
        if (obj !== null && typeof(obj) === "object") {
            if (Array.isArray(obj)) {
                const i = obj.indexOf(element);
                if (i > -1) obj.splice(i, 1);
            } else {
                if (Object.keys(obj).includes(element)) delete(obj[element]);
            }
        }
        return obj;
    },
    find: (arr, filter, cb, ecb) => {
        const item = Array.isArray(arr) && typeof(filter) === "function" ? arr.find(filter) : undefined;
        if (item !== undefined) return cb(item);
        else if (typeof(ecb) === "function") return ecb();
    },
    filter: (arr, filter, cb, ecb) => {
        const alt = () => typeof(ecb) === "function" && ecb(); 
        if (Array.isArray(arr)) {
            if (typeof(filter) !== "function") {
                if (arr.length) return cb(arr);
                else return alt();
            } else {
                const items = arr.filter(filter);
                if (items.length) return cb(items);
                else return alt();
            }
        } else return alt();
    }
};
export default CoreTools;